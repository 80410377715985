.App {
  text-align: center;
}

p {
  font-weight: 300;
}

.App-link {
  color: #61dafb;
}

.h100 {
  height: 100px;
}

.banner {
  height: 100px;
  background-color: orange;
  text-align: center;
}

.banner-btn {
  margin: auto;
  margin-top: 25px;
}

.bg-light-blue {
  background-color: #cce6ff !important;
}

.bg-orange {
  background-color: #ff9900;
  color: white;
}

.bg-blue {
  background-color: #81ADF4;
}

.white {
  color: white;
}

.blue-highlight {
  background-color: #81ADF4;
  color: white;
  width: auto;
}

.lessons-img {
  width: 80%;
  height: auto;
}

.no-dec {
  color: inherit;
  text-decoration: none;
  transition:
}

.no-dec:hover {
  color: inherit;
  font-weight: 800;
  text-decoration: none;
}

.bg-servers {
  background-image: url('images/serve_sketch.webp');
  filter: grayscale(100%);
  background-size: cover;
  background-position: center;
  height: 80vh;
}

.blue {
  color: #81ADF4;
}

.blue-inverted {
  color: white;
  background-color: #81ADF4;
}

.logo-in-servers {
  margin-top: 50%;
  margin-left: 30px;
}

.center-y {
  margin-top: auto;
  margin-bottom: auto;
}

.btn-primary {
  background-color: #81ADF4;
}

.btn-primary:hover {
  background-color: #cce6ff;
  text-decoration: none;
  text-decoration: none !important;
  color: black;
}

.undecorated {
  list-style-type: none;
}

.centered-ul {
  display: table;
  margin: 0 auto;
}

#root {
  margin: 0;
}

.justify {
  text-align: justify;
}

.sticky-nav {
  position: sticky;
  top: 0 !important;
  z-index: 1;
  -webkit-transform: translate3d(0,0,0);
}

.logo {
  height: 60px;
  width: auto;
  margin-right: 20px;
}

.navbar-brand {
  color: #81ADF4 !important;
  font-weight: 600;
}

@media screen and (max-width: 420px) {
  .logo {
    height: 0;
    width: auto;
  }

  .navbar-brand {
    margin-left: auto;
    margin-right: auto;
  }
}

.servers {
  background-image:linear-gradient(
      rgba(0, 100, 240, 0.4),
      rgba(0, 0, 0, 0.1)
    ), url('./images/servers.webp');
  /* -webkit-filter: grayscale(100%); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 50vh;
}

.servers-text-div {
  color: white;
  padding-top: 10vh;
  padding-left: 10vw;
}

.servers-text {
  font-weight: 800;
}

.btn-wrap-text {
    white-space: normal !important;
    word-wrap: break-word !important;
}

.border-top-1 {
  border-top: 1px solid #81ADF4;
}

.brightness-150 {
  filter: brightness(1.2);
  filter: contrast(1.3);
}
/* 81ADF4 */

strong {
  font-weight: 800;
}
